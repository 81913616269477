@font-face {
  font-family: 'Product Sans';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url(./assets/fonts/ProductSansRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'Product Sans';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(./assets/fonts/ProductSansBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Product Sans';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url(./assets/fonts/ProductSansBlack.woff) format('truetype');
}

body,
html {
  margin: 0;
  font-family: 'Inter';
}

body {
  background-color: var(--joy-palette-background-surface);
}
::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--joy-palette-background-level1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--joy-palette-primary-500);
}
